import {
  clearDeals,
  closeSaveDealSuccess,
  createDealSuccess,
  fetchAndExportDeals,
  fetchAndExportDealsFailed,
  fetchAndExportDealsSuccess,
  fetchDeals,
  fetchDealsFailed,
  fetchDealsSuccess,
  updateDealSuccess,
} from "../actions/dealActions"

import { Deal } from "../types/dealTypes"
import { PaginatedComponentState } from "../types/sharedTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialDealState extends PaginatedComponentState {
  deals: Partial<Deal>[]
  isLoading: boolean
  isLoadingDealsForExport: boolean
  dealSuccessMessage: string
}

const initialState: InitialDealState = {
  deals: [],
  isLoading: true,
  isLoadingDealsForExport: false,
  dealSuccessMessage: "",

  // PaginatedComponentState
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Deal List action handlers ================================*/
    .addCase(fetchDeals, (state) => {
      state.isLoading = true
    })
    .addCase(fetchDealsSuccess, (state, action) => {
      state.isLoading = false
      state.deals = action.payload.deals
      state.deals.forEach((deal) => {
        deal.wseatIds = deal.wseatIds ?? []
      })
      state.pager = action.payload.pager
    })
    .addCase(fetchDealsFailed, (state) => {
      state.isLoading = false
    })
    .addCase(fetchAndExportDeals, (state) => {
      state.isLoadingDealsForExport = true
    })
    .addCase(fetchAndExportDealsSuccess, (state, action) => {
      state.dealSuccessMessage = action.payload
      state.isLoadingDealsForExport = false
    })
    .addCase(fetchAndExportDealsFailed, (state) => {
      state.isLoadingDealsForExport = false
    })
    .addCase(clearDeals, () => initialState)
    // /*================================ Deal Form action handlers ================================*/
    .addCase(createDealSuccess, (state, action) => {
      state.dealSuccessMessage = `Successfully created deal: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(updateDealSuccess, (state, action) => {
      state.dealSuccessMessage = `Successfully updated deal: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeSaveDealSuccess, (state) => {
      state.dealSuccessMessage = initialState.dealSuccessMessage
    })
)
