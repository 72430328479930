import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
  PlusCircle as PlusCircleIcon,
  Search as SearchIcon,
} from "react-feather"
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { PAGE_LIMIT, PERMISSIONS } from "../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  clearDomains,
  closeSaveDomainSuccess,
  fetchDomains,
} from "../../../../../redux/actions/domainActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../common/Can"
import ErrorMessages from "../../../../common/Errors"
import NewHeader from "../../../../NewHeader"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Link as RouterLink } from "react-router-dom"
import TablePaginationActions from "../../../../common/TablePaginationActions"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "All Domains"
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Domains",
    },
  ]

  const headerButtons = [
    {
      name: "CREATE NEW DOMAIN",
      route: `/admin/domains/create`,
      permission: PERMISSIONS.ADMIN.DOMAIN.CREATE,
      icon: <PlusCircleIcon />,
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const DomainsList = ({ classes }) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.domains.isLoading)
  const domains = useSelector((state: RootState) => state.domains.domains)
  const domainSuccessMessage = useSelector((state: RootState) => state.domains.domainSuccessMessage)
  const pager = useSelector((state: RootState) => state.domains.pager)

  const sortOptions = [
    {
      value: "id|DESC",
      label: "ID (descending)",
    },
    {
      value: "id|ASC",
      label: "ID (ascending)",
    },
    {
      value: "name|ASC",
      label: "Name (ascending)",
    },
    {
      value: "name|DESC",
      label: "Name (descending)",
    },
  ]

  const [query, setQuery] = useState("")
  const [sortByFilter, setSortByFilter] = useState({
    value: "id",
    order: "DESC",
  })
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [selectedPage, setSelectedPage] = useState(0)

  const dispatchFetchDomains = (value = query) => {
    dispatch(
      fetchDomains({
        pagination: {
          limit: pageLimit,
          page: selectedPage,
        },
        sort: {
          value: sortByFilter.value,
          order: sortByFilter.order,
        },
        filters: {
          term: value,
        },
      })
    )
  }

  useEffect(dispatchFetchDomains, [pageLimit, sortByFilter, selectedPage])

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearDomains())
      dispatch(closeSaveDomainSuccess())
    }
  }, [])

  const debounceSearch = useCallback(debounceFn(dispatchFetchDomains), [pageLimit, sortByFilter])

  const handleQueryChange = (event) => {
    setQuery(event.target.value)
    debounceSearch(event.target.value)
    setSelectedPage(0)
  }
  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
  }
  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }
  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo()
  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        <ErrorMessages />
        {domainSuccessMessage.length > 0 && (
          <Alert
            severity="success"
            key={domainSuccessMessage}
            onClose={() => dispatch(closeSaveDomainSuccess())}
          >
            {domainSuccessMessage}
          </Alert>
        )}
        <Card data-testid="domains-list-table-container">
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search domains"
              value={query}
              variant="outlined"
              data-testid={"domains-list-search-input"}
            />
            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"domains-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <Box flexGrow={1} />
            <Box minHeight={56} display="flex" alignItems="right">
              <TablePagination
                component="div"
                count={pager.totalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={selectedPage}
                rowsPerPage={pageLimit}
                rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
                data-testid="domains-list-pagination"
                ActionsComponent={TablePaginationActions}
              />
            </Box>
          </Box>
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell data-testid={"domains-list-id-table-column-header"}>ID</TableCell>
                    <TableCell data-testid={"domains-list-name-table-column-header"}>
                      Name
                    </TableCell>
                    <TableCell data-testid={"domains-list-subdomains-table-column-header"}>
                      Subdomains
                    </TableCell>
                    <Can
                      perform={[PERMISSIONS.ADMIN.DOMAIN.VIEW, PERMISSIONS.ADMIN.DOMAIN.UPDATE]}
                      yes={
                        <TableCell
                          align="right"
                          data-testid={"domains-list-actions-table-column-header"}
                        >
                          Actions
                        </TableCell>
                      }
                    />
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody data-testid={"domains-list-content"}>
                    {domains.map((domain) => {
                      return (
                        <TableRow hover key={domain.id}>
                          {/* ID */}
                          <TableCell data-testid={`domains-list-id-${domain.id}`}>
                            {domain.id}
                          </TableCell>

                          {/* Name */}
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/admin/domains/${domain.id}/subdomains`}
                                  variant="h6"
                                  data-testid={`domains-list-name-${domain.id}`}
                                >
                                  {domain.name}
                                </Link>
                              </div>
                            </Box>
                          </TableCell>

                          {/* Subdomains count and link */}
                          <TableCell data-testid={`domains-list-subdomains-${domain.id}`}>
                            <Button
                              color="secondary"
                              variant="contained"
                              to={`/admin/domains/${domain.id}/subdomains`}
                              component={RouterLink}
                              disabled={!domain.subdomainsCount}
                              data-testid={`domains-list-subdomains-button-${domain.id}`}
                            >
                              {`List (${domain.subdomainsCount})`}
                            </Button>
                          </TableCell>

                          {/* Actions */}
                          <Can
                            perform={[
                              PERMISSIONS.ADMIN.DOMAIN.VIEW,
                              PERMISSIONS.ADMIN.DOMAIN.UPDATE,
                            ]}
                            yes={
                              <TableCell align="right">
                                <Can
                                  perform={PERMISSIONS.ADMIN.DOMAIN.UPDATE}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/domains/${domain.id}/edit`}
                                      data-testid={`domains-list-edit-button-${domain.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <EditIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                                <Can
                                  perform={PERMISSIONS.ADMIN.DOMAIN.VIEW}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/domains/${domain.id}`}
                                      data-testid={`domains-list-view-button-${domain.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ArrowRightIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                              </TableCell>
                            }
                          />
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
            data-testid="domains-list-pagination"
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
      <Box sx={{ height: "24px" }} />
    </>
  )
}

export default withStyles(styles)(DomainsList)
