import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchFeatureFlagByTerm,
  fetchFeatureFlagByTermSuccess,
  fetchFeatureFlagByTermFailed,
  setFeatureFlagToFalseOnFetchFailure,
  setFeatureFlagsLoadingFlag,
} from "../actions/featureFlagActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchFeatureFlagByTermSaga(action: PayloadAction<string>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/configuration/feature-flags/find-one`,
    data: { term: action.payload },
  }

  yield put(clearErrors())
  yield put(setFeatureFlagsLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(
      fetchFeatureFlagByTermSuccess({
        featureFlag: response.data,
      })
    )
  } catch (err) {
    yield handleSagaError(fetchFeatureFlagByTermFailed, err)
    yield put(setFeatureFlagToFalseOnFetchFailure(action.payload))
  }
  yield put(setFeatureFlagsLoadingFlag(false))
}

export function* watchFetchFeatureFlagByTerm(): SagaIterator {
  yield takeEvery(fetchFeatureFlagByTerm, fetchFeatureFlagByTermSaga)
}
