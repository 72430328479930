import * as Yup from "yup"

import {
  AUCTION_TYPE,
  DEAL_CSV_TYPES,
  DEVICE_TYPE,
  DMP_SEGMENT_FEE,
  DMP_SEGMENT_FEE_TYPE,
  END_PATHS,
  ENTITY_FILTER_TYPE,
  ENTITY_STATUSES,
  MAX_DOMAINS_INPUT,
  MAX_IAB_CONTENT_CATEGORIES_INPUT,
  MAX_PUBLISHERS_INPUT,
  MEDIA_TYPE,
  OPERATION_TYPES_ENP,
  PERMISSIONS,
  SEGMENT_VENDOR_NAME,
  SUPPLY_TYPE,
} from "../../../../../constants"
import { Alert, Autocomplete } from "@material-ui/lab"
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  Hidden,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { CreateAndUpdateDealPayload, Deal } from "../../../../../redux/types/dealTypes"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  clearDealDomains,
  clearPublishers,
  clearUploadedDealDomains,
  clearUploadedDealPublishers,
  createDeal,
  fetchDealBidders,
  fetchDealById,
  fetchDealCountries,
  fetchDealDomains,
  fetchDealExternalAudiences,
  fetchDealIabContentCategories,
  fetchDealPublishers,
  resetDealFormState,
  updateDeal,
  uploadDealDomainsSuccess,
  uploadDealPublishersSuccess,
} from "../../../../../redux/actions/dealActions"
import {
  flattenFormattedSegmentGroups,
  formatRtbxSegmentGroups,
  formatSegmentGroups,
} from "../../../../../helpers/segmentHelpers"
import {
  toStringArray,
  trimPayloadProperties,
  validateArray,
} from "../../../../../helpers/formatterHelper"

import AudiencesList from "../../Audience/AudiencesList"
import { Bidder } from "../../../../../redux/types/sharedTypes"
import Can from "../../../../common/Can"
import ChipInput from "material-ui-chip-input"
import { CloudDownloadOutlined } from "@material-ui/icons"
import CsvUpload from "./CsvUpload"
import { Edit as EditIcon } from "react-feather"
import ErrorMessages from "../../../../common/Errors"
import { Formik } from "formik"
import InputAdornment from "@material-ui/core/InputAdornment"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import NewHeader from "../../../../NewHeader/NewHeader"
import SegmentGroupLogicSummary from "../../../../common/SegmentGroupLogicSummary"
import _ from "lodash"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import clsx from "clsx"
import dayjs from "dayjs"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import { exportFilterListAsCsv } from "../../../../../helpers/csvHelpers"
import { fetchSegments } from "../../../../../redux/actions/segmentActions"
import styles from "./styles"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

dayjs.extend(LocalizedFormat)

export const buildHeaderInfo = (
  isCreate,
  isEdit,
  isInfo,
  isCopy,
  deal: Deal = {} as Deal
): HeaderInfo => {
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Deals",
      route: "/tools/deals",
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Deal"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isEdit) {
    headerText = "Deal: "
    if (deal.id) {
      headerText = `Deal: ${deal.name} (${deal.id})`
      breadcrumbs.push({
        name: `${deal.name} (${deal.id})`,
        route: `/tools/deals/${deal.id}`,
      })
      breadcrumbs.push({
        name: `Edit`,
      })
    }
  }

  if (isInfo) {
    headerText = "Deal: "
    if (deal.id) {
      headerText = `Deal: ${deal.name} (${deal.id})`
      breadcrumbs.push({
        name: `${deal.name} (${deal.id})`,
      })

      headerButtons.push({
        name: "EDIT",
        route: `/tools/deals/${deal.id}/edit`,
        permission: PERMISSIONS.TOOLS.DEAL.UPDATE,
        icon: <EditIcon />,
      })
    }
  }

  if (isCopy) {
    headerText = "Copy of Deal: "
    if (deal.id) {
      headerText = `Copy of Deal: ${deal.name} (${deal.id})`
      breadcrumbs.push({
        name: `${deal.name} (${deal.id})`,
        route: `/tools/deals/${deal.id}`,
      })

      breadcrumbs.push({
        name: `Copy`,
      })
    }
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const DealForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()

  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)

  const isSaved = useSelector((state: RootState) => state.dealForm.isSaved)
  const isLoading = useSelector((state: RootState) => state.dealForm.isLoading)
  const isLoadingBidders = useSelector((state: RootState) => state.dealForm.isLoadingBidders)
  const isLoadingCountries = useSelector((state: RootState) => state.dealForm.isLoadingCountries)
  const isLoadingIabContentCategories = useSelector(
    (state: RootState) => state.dealForm.isLoadingIabContentCategories
  )
  const isLoadingDomains = useSelector((state: RootState) => state.dealForm.isLoadingDomains)
  const isLoadingPublishers = useSelector((state: RootState) => state.dealForm.isLoadingPublishers)
  const isLoadingExternalAudiences = useSelector(
    (state: RootState) => state.dealForm.isLoadingExternalAudiences
  )
  const dealsSegments = useSelector((state: RootState) => state.segments.dealsSegments)

  const selectableIabContentCategories = useSelector(
    (state: RootState) => state.dealForm.iabContentCategories
  )

  const selectableBidders = useSelector((state: RootState) => state.dealForm.bidders)
  const selectableCountries = useSelector((state: RootState) => state.dealForm.countries)

  const selectableExternalAudiences = useSelector(
    (state: RootState) => state.dealForm.externalAudiences
  )
  const selectableDomains = useSelector((state: RootState) => state.dealForm.domains)
  const domainsPage = useSelector((state: RootState) => state.dealForm.domainsPage)
  const [domainsSearchTerm, setDomainsSearchTerm] = useState("")

  const selectablePublishers = useSelector((state: RootState) => state.dealForm.publishers)
  const publishersPage = useSelector((state: RootState) => state.dealForm.publishersPage)
  const [publishersSearchTerm, setPublishersSearchTerm] = useState("")

  // state passed into CsvUpload component
  const uploadedDomains = useSelector((state: RootState) => state.dealForm.uploadedDomains)
  const uploadedPublishers = useSelector((state: RootState) => state.dealForm.uploadedPublishers)

  const deal = useSelector((state: RootState) => state.dealForm.selectedDeal)
  const isSubmitting = useSelector((state: RootState) => state.dealForm.isSubmitting)
  const generalErrors = useSelector((state: RootState) => state.errors.generalErrors)

  const history = useHistory()
  const endPath = match.path.split("/").pop()

  const isCreate = endPath === END_PATHS.CREATE
  const isEdit = endPath === END_PATHS.EDIT
  const isCopy = endPath === END_PATHS.COPY
  const isInfo = !isCreate && !isEdit && !isCopy

  const [isCopyDeal, setIsCopyDeal] = useState(false)
  const canCopy = isEdit && deal.status !== ENTITY_STATUSES.ARCHIVED

  const [biddersSearchListInput, setBiddersSearchListInput] = useState("")

  const isFocusedCompanyAudigent =
    currentUser?.focusedCompany?.name === SEGMENT_VENDOR_NAME.AUDIGENT
  const [isDataConnectedDeal, setIsDataConnectedDeal] = useState("false")

  useEffect(() => {
    // checks if form is create or edit, if edit, fetch deal info and pre-populate form data
    if ((isInfo || isEdit || isCopy) && !deal.id) dispatch(fetchDealById(match.params.dealId))

    setBiddersSearchListInput(deal.bidder.name)
    deal.dmpSegmentLogic !== OPERATION_TYPES_ENP.NONE && setIsDataConnectedDeal("legacy")
    deal.externalAudienceId !== null && setIsDataConnectedDeal("audience")

    isCopyDeal && history.push(`/tools/deals/${deal.id}/copy`)
    // when the deal is successfully created navigate back to deals list
    isSaved && history.push("/tools/deals")
  }, [deal, isSaved, isCopyDeal])

  useEffect(() => {
    dispatch(
      fetchDealExternalAudiences({ pagination: { paginate: false }, filters: { status: "active" } })
    )
    dispatch(fetchDealBidders())
    dispatch(fetchDealCountries())

    dispatch(fetchDealIabContentCategories())
    // clean up stale data when component unmounts
    return () => {
      // dispatch(clearAudiences())
      dispatch(clearErrors())
      dispatch(resetDealFormState())
    }
  }, [])

  //Fetch segments only if dataConnectedDeal selected
  useEffect(() => {
    if (!dealsSegments.length && isDataConnectedDeal === "legacy") {
      dispatch(
        fetchSegments({
          filters: {
            segmentVendorId: null,
          },
        })
      )
    }
  }, [isDataConnectedDeal])

  const dispatchFetchDealDomains = (searchTerm: string, pageNumber: 0, clear: true): void => {
    dispatch(
      fetchDealDomains({
        term: searchTerm,
        page: pageNumber,
        clear,
      })
    )
  }
  const dispatchFetchDealPublishers = (searchTerm: string, pageNumber: 0, clear: true): void => {
    dispatch(
      fetchDealPublishers({
        term: searchTerm,
        page: pageNumber,
        clear,
      })
    )
  }

  const debounceFetchDealDomains = useCallback(debounceFn(dispatchFetchDealDomains), [])
  const debounceFetchDealPublishers = useCallback(debounceFn(dispatchFetchDealPublishers), [])
  const getDmpSegmentFeeType = () => {
    if (deal.dmpSegmentFeeType) {
      return deal.dmpSegmentFeeType
    } else if (isFocusedCompanyAudigent) {
      return DMP_SEGMENT_FEE_TYPE.PERCENTAGE
    }
    return DMP_SEGMENT_FEE_TYPE.FIXED
  }
  const getDmpSegmentFee = () => {
    if (deal.dmpSegmentFee) {
      return deal.dmpSegmentFee
    } else if (
      isFocusedCompanyAudigent &&
      (deal.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.PERCENTAGE ||
        deal.dmpSegmentFeeType === null)
    ) {
      return DMP_SEGMENT_FEE.PERCENTAGE_AUDIGENT
    }
    return DMP_SEGMENT_FEE.DEFAULT
  }

  const isOriginalDealDataConnected =
    deal.dmpSegmentLogic !== OPERATION_TYPES_ENP.NONE
      ? "legacy"
      : deal.externalAudienceId != null
      ? "audience"
      : "false"
  const initialFormValues = isCreate
    ? {
        status: ENTITY_STATUSES.INACTIVE,
        name: "",
        auctionType: AUCTION_TYPE.STANDARD,
        bidder: "",
        mediaType: MEDIA_TYPE.ALL,
        supplyType: SUPPLY_TYPE.ALL,
        deviceType: DEVICE_TYPE.ALL,
        price: 1,
        cmpCheck: false,
        cmpFee: "",
        iabContentCategoryFilterType: ENTITY_FILTER_TYPE.NONE,
        domainFilterType: ENTITY_FILTER_TYPE.NONE,
        publisherFilterType: ENTITY_FILTER_TYPE.NONE,
        wseatIds: [],
        viewRate: "",
        vcrRate: "",
        countries: [{ id: 840, name: "United States" }],
        iabContentCategories: [],
        domains: [],
        publishers: [],
        segmentGroups: [],
        dataConnected: "false",
        dmpSegmentFeeType: isFocusedCompanyAudigent
          ? DMP_SEGMENT_FEE_TYPE.PERCENTAGE
          : DMP_SEGMENT_FEE_TYPE.FIXED,
        dmpSegmentFee: isFocusedCompanyAudigent
          ? DMP_SEGMENT_FEE.PERCENTAGE_AUDIGENT
          : DMP_SEGMENT_FEE.DEFAULT,
        externalAudienceId: "",
        externalAudience: "",
      }
    : {
        status: deal.status,
        name: isCopy ? `${deal.name}__copy` : deal.name ?? "",
        auctionType: deal.auctionType,
        bidder: deal.bidder,
        mediaType: deal.mediaType,
        supplyType: deal.supplyType,
        deviceType: deal.deviceType,
        price: deal.price || "",
        cmpCheck: deal.cmpCheck,
        cmpFee: deal.cmpFee ?? "",
        iabContentCategoryFilterType: deal.iabContentCategoryFilterType,
        domainFilterType: deal.domainFilterType,
        publisherFilterType: deal.publisherFilterType,
        wseatIds: deal.wseatIds,
        viewRate: deal.viewRate || "",
        vcrRate: deal.vcrRate || "",
        countries: selectableCountries.length > 0 ? validateArray(deal.countries) : [],
        iabContentCategories:
          selectableIabContentCategories.length > 0 ? validateArray(deal.iabContentCategories) : [],
        domains: validateArray(deal.domains),
        publishers: validateArray(deal.publishers),
        segmentGroups: formatRtbxSegmentGroups(validateArray(deal.segmentGroups)),
        dataConnected: isOriginalDealDataConnected,
        dmpSegmentFeeType: getDmpSegmentFeeType(),
        externalAudienceId: deal.externalAudienceId ?? "",
        dmpSegmentFee: getDmpSegmentFee(),
        externalAudience: deal.externalAudience,
      }
  const formValidationSchema = Yup.object().shape({
    // Deal details
    name: Yup.string().min(1).max(255, "Name is too long").required("Name is required"),
    auctionType: Yup.string().required(),
    status: Yup.string(),

    // Terms
    price: Yup.number().positive().nullable(true),
    cmpFee: Yup.number()
      .positive()
      .max(100)
      .nullable(true)
      .test(
        "has-one-decimal",
        "CMP Fee must not have more than one decimal",
        (cmpFee) => cmpFee == null || cmpFee.toString().match(/^\d*\.?\d{0,1}$/) !== null
      ),

    // Demand
    bidder: Yup.object().shape({
      id: Yup.number().min(1).required("Buyer is required"),
      name: Yup.string(),
    }),
    wseatIds: Yup.array()
      .test(
        "digit length",
        "W Seats cannot have more than 9 digits",
        (wseatValues) => !wseatValues.some((seatValue) => seatValue.length > 9)
      )
      .test(
        "numeric type",
        "W Seats must be numbers",
        (wseatValues) => !wseatValues.some((seatValue) => parseInt(seatValue) != seatValue)
      ),

    // Supply: Who
    dataConnected: Yup.string().oneOf(["false", "legacy", "audience"]),
    dmpSegmentFeeType: Yup.string(),
    dmpSegmentFee: Yup.number()
      .when(["dataConnected", "dmpSegmentFeeType"], {
        is: (dataConnected, dmpSegmentFeeType) => {
          return dataConnected !== "false" && dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.PERCENTAGE
        },
        then: Yup.number()
          .min(0, "Please select a value between 0 and 100")
          .max(100, "Please select a value between 0 and 100")
          .required("DMP Fee Percentage is required for data connected deals")
          .test(
            "has-one-decimal",
            "DMP Fee Percentage must not have more than one decimal",
            (dmpSegmentFee) =>
              dmpSegmentFee === null || dmpSegmentFee?.toString().match(/^\d*\.?\d{0,1}$/) !== null
          ),
      })
      .when(["dataConnected", "dmpSegmentFeeType"], {
        is: (dataConnected, dmpSegmentFeeType) => {
          return dataConnected !== "false" && dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.FIXED
        },
        then: Yup.number()
          .min(0, "Please select a value between 0 and 100")
          .max(100, "Please select a value between 0 and 100")
          .required("DMP Fee is required for data connected deals")
          .test(
            "has-two-decimal",
            "DMP Fee must not have more than two decimal",
            (dmpSegmentFee) =>
              dmpSegmentFee === null || dmpSegmentFee?.toString().match(/^\d*\.?\d{0,2}$/) !== null
          ),
      }),
    externalAudienceId: Yup.number()
      .integer()
      .positive()
      .when("dataConnected", {
        is: "audience",
        then: Yup.number().required("Audience ID is required for data connected deals"),
      }),
    segmentGroups: Yup.array()
      .transform((segmentGroups) => flattenFormattedSegmentGroups(segmentGroups))
      .when("dataConnected", {
        is: "legacy",
        then: Yup.array().min(1, "At least one segment group is required for data connected deals"),
      }),

    // Supply: What
    iabContentCategories: Yup.array().when("iabContentCategoryFilterType", {
      is: (val) => val && val !== ENTITY_FILTER_TYPE.NONE,
      then: Yup.array(Yup.object())
        .min(1, "At least one category is required when filter type is selected")
        .max(
          MAX_IAB_CONTENT_CATEGORIES_INPUT,
          `Please select fewer than ${MAX_IAB_CONTENT_CATEGORIES_INPUT} categories`
        ),
    }),

    // Supply: Where
    countries: Yup.array(),
    mediaType: Yup.string().max(255),
    supplyType: Yup.string().max(255),
    deviceType: Yup.string().max(255),

    publisherFilterType: Yup.string().oneOf(Object.values(ENTITY_FILTER_TYPE)),
    publishers: Yup.array().when("publisherFilterType", {
      is: (val) => val !== ENTITY_FILTER_TYPE.NONE,
      then: Yup.array(Yup.object())
        .min(1, "At least one publisher is required when filter type is selected")
        .max(MAX_PUBLISHERS_INPUT, `Please select fewer than ${MAX_PUBLISHERS_INPUT} publishers`),
    }),
    domainFilterType: Yup.string().oneOf(Object.values(ENTITY_FILTER_TYPE)),
    domains: Yup.array().when("domainFilterType", {
      is: (val) => val && val !== ENTITY_FILTER_TYPE.NONE,
      then: Yup.array(Yup.object())
        .min(1, "At least one domain is required when filter type is selected")
        .max(MAX_DOMAINS_INPUT, `Please select fewer than ${MAX_DOMAINS_INPUT} domains`),
    }),

    viewRate: Yup.number()
      .integer("Minimum viewability rate must be an integer")
      .strict()
      .positive()
      // Product asked that a max be set but that it also accepts null
      .max(100, "Rate must be smaller than or equal to 100")
      .nullable(true),
    vcrRate: Yup.number()
      .integer("Minimum Video Completion Rate must be an integer")
      .strict()
      .positive()
      // Product asked that a max be set but that it also accepts null
      .max(100, "Rate must be smaller than or equal to 100")
      .nullable(true),
    iabContentCategoryFilterType: Yup.string().oneOf(Object.values(ENTITY_FILTER_TYPE)),
  })

  const dealFormStatusOptions = [
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
  ]
  const dataConnectedOptions = [
    { value: "false", label: "Everyone" },
    { value: "legacy", label: "Data Connected", shouldHide: true },
    { value: "audience", label: "Audience Connected" },
  ]
  const dealTypeOptions = [
    { value: AUCTION_TYPE.STANDARD, label: "Standard" },
    { value: AUCTION_TYPE.FIXED_PRICE, label: "Fixed Price" },
  ]
  const mediaTypeOptions = [
    { value: MEDIA_TYPE.DISPLAY, label: "Display" },
    { value: MEDIA_TYPE.VIDEO, label: "Video" },
    { value: MEDIA_TYPE.ALL, label: "Display And Video" },
  ]
  const supplyTypeOptions = [
    { value: SUPPLY_TYPE.WEB, label: "Web" },
    { value: SUPPLY_TYPE.APP, label: "App" },
    { value: SUPPLY_TYPE.ALL, label: "Web And App" },
  ]
  const deviceTypeOptions = [
    { value: DEVICE_TYPE.DESKTOP, label: "Desktop" },
    { value: DEVICE_TYPE.MOBILE, label: "Mobile" },
    { value: DEVICE_TYPE.CTV, label: "CTV" },
    { value: DEVICE_TYPE.ALL, label: "Desktop, Mobile & CTV" },
  ]
  const entityFilterTypeOptions = [
    { value: ENTITY_FILTER_TYPE.NONE, label: "None" },
    { value: ENTITY_FILTER_TYPE.ALLOWLIST, label: "Allowlist" },
    { value: ENTITY_FILTER_TYPE.BLOCKLIST, label: "Blocklist" },
  ]

  const steps = [
    //
    { title: "Deal Details", subTitle: "" },
    { title: "Terms", subTitle: "" },
    { title: "Demand", subTitle: "" },
    { title: "Supply:", subTitle: "Who's Consuming Content?" },
    { title: "Supply:", subTitle: "What Content Are They Consuming?" },
    { title: "Supply:", subTitle: "Where Are They Consuming Content?" },
  ]

  const groupIabContentCategories = (iabContentCategory) => {
    const categoryGroupHeader = selectableIabContentCategories.find(
      ({ iab }) => iab === iabContentCategory.iab.split("-")[0]
    )
    return categoryGroupHeader?.name || "None"
  }

  const handleCopyDealClick = () => {
    setIsCopyDeal(true)
  }

  const isDisabled = isInfo || isLoading || isSubmitting
  const getStepContent = (
    step: number,
    values,
    touched,
    setFieldTouched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue
  ) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
            {/*Name*/}
            <Grid item md={6} xs={12}>
              <TextField
                label={<span className={clsx(isInfo && classes.overrideDisable)}>Deal Name</span>}
                name="name"
                error={!!(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.name}
                inputProps={{
                  "data-testid": "deal-form-name-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                disabled={isDisabled}
              >
                Name
              </TextField>
              {deal.createdAt && (
                <>
                  <Typography>
                    <b>Created on: </b>
                    {dayjs(deal.createdAt).format("M-D-YYYY h:mm a")}
                    {deal.updatedAt && (
                      <>
                        <b> Last updated: </b>
                        {dayjs(deal.updatedAt).format("M-D-YYYY h:mm a")}
                      </>
                    )}
                  </Typography>
                </>
              )}
            </Grid>
            {/* Auction Type*/}
            <Grid item md={3} xs={12}>
              <TextField
                select
                label={
                  <span className={clsx(isInfo && classes.overrideDisable)}>Auction Type</span>
                }
                name="auctionType"
                error={!!(touched.auctionType && errors.auctionType)}
                helperText={touched.auctionType && errors.auctionType}
                value={values.auctionType}
                onChange={handleChange}
                className={classes.dropDownField}
                inputProps={{
                  "data-testid": "deal-form-auction-type-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                disabled={isDisabled}
              >
                {dealTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/*Status*/}
            <Grid item md={3} xs={12}>
              <TextField
                select
                label={<span className={clsx(isInfo && classes.overrideDisable)}>Status</span>}
                name="status"
                error={!!(touched.status && errors.status)}
                helperText={touched.status && errors.status}
                value={values.status}
                onChange={handleChange}
                className={classes.dropDownField}
                inputProps={{
                  "data-testid": "deal-form-status-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                disabled={isDisabled}
              >
                {dealFormStatusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )
      case 1:
        return (
          <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
            {/*Price*/}
            <Grid item md={2} xs={12}>
              <TextField
                label={<span className={clsx(isInfo && classes.overrideDisable)}>Price ($)</span>}
                name="price"
                className={classes.dropDownField}
                type="number"
                error={!!(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                inputProps={{
                  "data-testid": "deal-form-price-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                onWheel={(e) => (e.target as HTMLElement).blur()}
                disabled={isDisabled}
              />
            </Grid>

            {/*CMP Fee*/}
            <Can
              perform={PERMISSIONS.TOOLS.DEAL.UPDATE_CMP_FEE}
              yes={
                <Grid item md={2} xs={12}>
                  <TextField
                    label={
                      <span
                        className={clsx(isInfo && values.cmpFee > 0 && classes.overrideDisable)}
                      >
                        CMP Fee (%)
                      </span>
                    }
                    name="cmpFee"
                    className={classes.dropDownField}
                    type="number"
                    error={!!(touched.cmpFee && errors.cmpFee)}
                    helperText={touched.cmpFee && errors.cmpFee}
                    placeholder="N/A"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cmpFee}
                    inputProps={{
                      "data-testid": "deal-form-cmp-fee-input",
                      step: "any",
                    }}
                    InputProps={{
                      classes: {
                        disabled: isInfo && values.cmpFee > 0 && classes.overrideDisable,
                      },
                    }}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    disabled={isDisabled}
                  />
                </Grid>
              }
            />

            {/* DMP Fee */}
            {values.dataConnected !== "false" && (
              <Grid item md={2} xs={12}>
                <TextField
                  label={<span className={clsx(isInfo && classes.overrideDisable)}>DMP Fee</span>}
                  name="dmpSegmentFee"
                  className={classes.dropDownField}
                  error={!!(touched.dmpSegmentFee && errors.dmpSegmentFee)}
                  helperText={touched.dmpSegmentFee && errors.dmpSegmentFee}
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.dmpSegmentFee}
                  inputProps={{
                    "data-testid": "deal-form-dmp-segment-fee-input",
                  }}
                  InputProps={{
                    startAdornment: values.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.FIXED && (
                      <InputAdornment position="start">CPM $</InputAdornment>
                    ),
                    endAdornment: values.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.PERCENTAGE && (
                      <InputAdornment position="end">% of Bid</InputAdornment>
                    ),
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  disabled={isDisabled}
                />
                <RadioGroup
                  name="dmpSegmentFeeType"
                  className={classes.group}
                  value={values.dmpSegmentFeeType}
                  data-testid={"deal-form-dmp-fee-type"}
                  onChange={(selectedDmpSegmentFeeType) => {
                    setFieldValue("dmpSegmentFeeType", selectedDmpSegmentFeeType.target.value)
                    if (isFocusedCompanyAudigent) {
                      setFieldValue(
                        "dmpSegmentFee",
                        selectedDmpSegmentFeeType.target.value === DMP_SEGMENT_FEE_TYPE.PERCENTAGE
                          ? DMP_SEGMENT_FEE.PERCENTAGE_AUDIGENT
                          : DMP_SEGMENT_FEE.DEFAULT
                      )
                    }
                  }}
                >
                  <FormControlLabel
                    value={DMP_SEGMENT_FEE_TYPE.FIXED}
                    data-testid={"deal-form-dmp-fee-type-fixed"}
                    control={<Radio />}
                    label={
                      <span className={clsx(isInfo && classes.overrideDisable)}>Flat Rate CPM</span>
                    }
                    disabled={isDisabled}
                  />
                  <FormControlLabel
                    value={DMP_SEGMENT_FEE_TYPE.PERCENTAGE}
                    data-testid={"deal-form-dmp-fee-type-percentage"}
                    control={<Radio />}
                    label={
                      <span className={clsx(isInfo && classes.overrideDisable)}>% of Bid</span>
                    }
                    disabled={isDisabled}
                  />
                </RadioGroup>
              </Grid>
            )}
          </Grid>
        )
      case 2:
        return (
          <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                options={selectableBidders.filter((bidder) => bidder.status === "active")}
                value={values.bidder}
                fullWidth
                getOptionLabel={(option) => (option ? option.name : "")}
                getOptionSelected={(option, value) => value.id === option.id}
                data-testid={"deal-form-bidder-input"}
                onBlur={() => setFieldTouched("bidder")}
                onChange={(e, newValue: Bidder) => {
                  const newBidderValue = newValue ? newValue : ""
                  setFieldValue("bidder", newBidderValue)
                }}
                onInputChange={(e, newInputValue) => {
                  setBiddersSearchListInput(newInputValue)
                }}
                inputValue={biddersSearchListInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<span className={clsx(isInfo && classes.overrideDisable)}>Buyer</span>}
                    name={"bidder"}
                    error={!!(touched.bidder && errors.bidder)}
                    helperText={touched.bidder && errors.bidder?.id}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        disabled: classes.overrideDisable,
                      },
                    }}
                  />
                )}
                disabled={isDisabled || isLoadingBidders}
              />
            </Grid>
            <Hidden smDown>
              <Grid item md={6} />
            </Hidden>
            {/*Allowlist bidder seats*/}
            <Grid item md={6} xs={12}>
              <ChipInput
                label={
                  <span
                    className={clsx(
                      isInfo && values.wseatIds.length > 0 && classes.overrideDisable
                    )}
                  >
                    W Seat(s)
                  </span>
                }
                error={!!(touched.wseatIds && errors.wseatIds)}
                helperText={touched.wseatIds && errors.wseatIds}
                defaultValue={toStringArray(values.wseatIds)}
                onBlur={handleBlur}
                onChange={(newValue) => {
                  setFieldValue("wseatIds", newValue)
                }}
                data-testid={"deal-form-wseat-ids-input"}
                fullWidth
                variant="outlined"
                disabled={isDisabled}
              />
            </Grid>
            <Hidden smDown>
              <Grid item md={6} />
            </Hidden>
          </Grid>
        )
      case 3:
        return (
          <>
            <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
              <Grid item md={2} xs={12}>
                <Tooltip
                  title={
                    isEdit && isOriginalDealDataConnected === "false"
                      ? `Switching supply audience from "Everyone" to "Data Connected" is not possible`
                      : ""
                  }
                >
                  {/* Is data connected? input field*/}
                  <TextField
                    select
                    label={
                      <span className={clsx(isInfo && classes.overrideDisable)}>Audience</span>
                    }
                    name="dataConnected"
                    className={classes.dropDownField}
                    error={!!(touched.dataConnected && errors.segmentGroups)}
                    helperText={touched.dataConnected && errors.segmentGroups}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      setIsDataConnectedDeal(e.target.value)
                    }}
                    value={values.dataConnected}
                    inputProps={{
                      "data-testid": "deal-form-data-connected-input",
                    }}
                    InputProps={{
                      classes: {
                        disabled: classes.overrideDisable,
                      },
                    }}
                    disabled={isDisabled || (isEdit && isOriginalDealDataConnected === "false")}
                  >
                    {dataConnectedOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={option.shouldHide ? classes.hiddenOption : ""}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </Grid>
            </Grid>
            {values.dataConnected === "legacy" && (
              <>
                {!isInfo && (
                  <AudiencesList
                    selectedAudience
                    handleSelectAudience={(e, audience) => {
                      const newSegmentGroups = e.target.checked
                        ? formatSegmentGroups(audience.segmentGroups)
                        : formatRtbxSegmentGroups(deal.segmentGroups)
                      setFieldValue("segmentGroups", newSegmentGroups)
                    }}
                    testId={"deal-form-audiences-list"}
                    showHeader={false}
                    showErrors={false}
                    enableNavigation={false}
                    multiSelect={false}
                    selectLimit={6}
                    rowsPerPageOptions={[5]}
                  />
                )}
                <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
                  <Grid item md={6} xs={12}>
                    <ListSubheader className={classes.listSubheader}>
                      <Typography className={classes.segmentGroup}>
                        Currently Targeted Segment Groups:
                      </Typography>
                    </ListSubheader>
                    <SegmentGroupLogicSummary
                      segmentGroupsValue={values.segmentGroups}
                      segmentData={dealsSegments}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {values.dataConnected === "audience" && (
              <>
                <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      options={selectableExternalAudiences}
                      value={values.externalAudience}
                      getOptionSelected={(option, value) => value.segmentId === option.segmentId}
                      getOptionLabel={(option) =>
                        option ? `${option.segmentName} (${option.segmentId})` : ""
                      }
                      fullWidth
                      data-testid={"deal-form-external-audience-id-input"}
                      onBlur={() => setFieldTouched("externalAudienceId")}
                      onChange={(e, newValue) => {
                        if (newValue !== null) {
                          setFieldValue("externalAudience", newValue)
                          setFieldValue("externalAudienceId", newValue.segmentId)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span className={clsx(isInfo && classes.overrideDisable)}>
                              Select an Audience
                            </span>
                          }
                          name={"externalAudienceId"}
                          error={!!(touched.externalAudienceId && errors.externalAudienceId)}
                          helperText={touched.externalAudienceId && errors.externalAudienceId}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            classes: {
                              disabled: classes.overrideDisable,
                            },
                          }}
                        />
                      )}
                      disabled={isDisabled || isLoadingExternalAudiences}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )
      case 4:
        return (
          <>
            <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={selectableIabContentCategories}
                  value={values.iabContentCategories}
                  multiple
                  fullWidth
                  data-testid={"deal-form-iab-content-categories-input"}
                  getOptionSelected={(option, value) => value.id === option.id}
                  groupBy={(option) => groupIabContentCategories(option)}
                  getOptionLabel={(option) => (option ? `${option.iab} ${option.name}` : "")}
                  onChange={(event, newValue) => {
                    setFieldValue("iabContentCategories", newValue)
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span
                          className={clsx(
                            isInfo &&
                              values.iabContentCategories.length > 0 &&
                              classes.overrideDisable
                          )}
                        >
                          IAB Content Categories
                        </span>
                      }
                      name={"iabContentCategories"}
                      error={!!(touched.iabContentCategories && errors.iabContentCategories)}
                      helperText={touched.iabContentCategories && errors.iabContentCategories}
                      onBlur={handleBlur}
                      fullWidth
                      placeholder="IAB Content Categories"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        classes: {
                          disabled:
                            isInfo &&
                            values.iabContentCategories.length > 0 &&
                            classes.overrideDisable,
                        },
                      }}
                    />
                  )}
                  disabled={
                    isDisabled ||
                    isLoadingIabContentCategories ||
                    values.iabContentCategoryFilterType === ENTITY_FILTER_TYPE.NONE
                  }
                />
                {values.iabContentCategoryFilterType === ENTITY_FILTER_TYPE.NONE && !isInfo && (
                  <FormHelperText component="div">
                    <Typography
                      className={classes.helperText}
                      data-testid={"deal-form-iab-content-category-filter-helper-text"}
                    >
                      ** Select a IAB Content Category Filter Type to add IAB Content Categories **
                    </Typography>
                  </FormHelperText>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={
                    <span className={clsx(isInfo && classes.overrideDisable)}>
                      IAB Content Category Filter Type
                    </span>
                  }
                  name="iabContentCategoryFilterType"
                  error={
                    !!(touched.iabContentCategoryFilterType && errors.iabContentCategoryFilterType)
                  }
                  helperText={
                    touched.iabContentCategoryFilterType && errors.iabContentCategoryFilterType
                  }
                  className={classes.dropDownField}
                  value={values.iabContentCategoryFilterType}
                  onChange={(e) => {
                    e.target.value === ENTITY_FILTER_TYPE.NONE &&
                      setFieldValue("iabContentCategories", [])

                    setFieldValue("iabContentCategoryFilterType", e.target.value)
                  }}
                  inputProps={{
                    "data-testid": "deal-form-iab-content-categories-filter-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {entityFilterTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label.replace("list", "")}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Media Type */}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={<span className={clsx(isInfo && classes.overrideDisable)}>Media</span>}
                  name="mediaType"
                  error={!!(touched.mediaType && errors.mediaType)}
                  helperText={touched.mediaType && errors.mediaType}
                  className={classes.dropDownField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mediaType}
                  inputProps={{
                    "data-testid": "deal-form-media-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {mediaTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </>
        )
      case 5:
        return (
          <>
            <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={selectableCountries}
                  value={validateArray(values.countries)}
                  getOptionSelected={(option, value) => value.id === option.id}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  multiple
                  fullWidth
                  filterSelectedOptions
                  data-testid={"deal-form-countries-input"}
                  onBlur={() => setFieldTouched("countries")}
                  onChange={(e, newValue) => {
                    setFieldValue("countries", newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span className={clsx(isInfo && classes.overrideDisable)}>Countries</span>
                      }
                      name={"countries"}
                      error={!!(touched.countries && errors.countries)}
                      helperText={touched.countries && errors.countries}
                      fullWidth
                      onBlur={handleBlur}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        classes: {
                          disabled: classes.overrideDisable,
                        },
                      }}
                    />
                  )}
                  disabled={isDisabled || isLoadingCountries}
                />
              </Grid>
              {/*Supply Type*/}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={
                    <span className={clsx(isInfo && classes.overrideDisable)}>Supply Type</span>
                  }
                  name="supplyType"
                  error={!!(touched.supplyType && errors.supplyType)}
                  helperText={touched.supplyType && errors.supplyType}
                  className={classes.dropDownField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.supplyType}
                  inputProps={{
                    "data-testid": "deal-form-supply-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {supplyTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/*Device Type*/}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={<span className={clsx(isInfo && classes.overrideDisable)}>Devices</span>}
                  name="deviceType"
                  error={!!(touched.deviceType && errors.deviceType)}
                  helperText={touched.deviceType && errors.deviceType}
                  className={classes.dropDownField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deviceType}
                  inputProps={{
                    "data-testid": "deal-form-device-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {deviceTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
              {/* Add Publishers */}
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={selectablePublishers}
                  value={values.publishers}
                  getOptionLabel={(option) => (option ? `${option.id} - ${option.name}` : "")}
                  getOptionSelected={(option, value) => value.id === option.id}
                  multiple
                  fullWidth
                  filterSelectedOptions
                  data-testid={"deal-form-publishers-input"}
                  onBlur={() => setFieldTouched("publishers")}
                  onChange={(event, newValue) => {
                    newValue.length || dispatch(clearUploadedDealPublishers())
                    setPublishersSearchTerm("")
                    dispatch(clearPublishers())
                    setFieldValue("publishers", newValue)
                  }}
                  onInputChange={(event, inputValue) => {
                    setPublishersSearchTerm(inputValue)
                    dispatch(clearPublishers())
                    inputValue.length > 1 && debounceFetchDealPublishers(inputValue, 0, true)
                  }}
                  ListboxProps={{
                    onScroll: (event: React.SyntheticEvent) => {
                      const listboxNode = event.currentTarget
                      if (
                        listboxNode.scrollTop + listboxNode.clientHeight ===
                        listboxNode.scrollHeight
                      ) {
                        dispatch(
                          fetchDealPublishers({
                            term: publishersSearchTerm,
                            page: publishersPage + 1,
                            clear: false,
                          })
                        )
                      }
                    },
                  }}
                  loading={isLoadingPublishers}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={
                          <span
                            className={clsx(
                              isInfo && values.publishers.length > 0 && classes.overrideDisable
                            )}
                          >
                            Publishers
                          </span>
                        }
                        name="publishers"
                        error={!!(touched.publishers && errors.publishers)}
                        helperText={touched.publishers && errors.publishers}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            disabled:
                              isInfo && values.publishers.length > 0 && classes.overrideDisable,
                          },
                        }}
                      />
                    )
                  }}
                  disabled={isDisabled || values.publisherFilterType === ENTITY_FILTER_TYPE.NONE}
                />
                {values.publisherFilterType === ENTITY_FILTER_TYPE.NONE && !isInfo && (
                  <FormHelperText component="div">
                    <Typography
                      className={classes.helperText}
                      data-testid={"deal-form-publisher-filter-helper-text"}
                    >
                      ** Select a Publisher Filter Type to add Publishers **
                    </Typography>
                  </FormHelperText>
                )}
              </Grid>
              {/*Publisher Filter Type*/}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={
                    <span className={clsx(isInfo && classes.overrideDisable)}>
                      Publisher Filter Type
                    </span>
                  }
                  name="publisherFilterType"
                  error={!!(touched.publisherFilterType && errors.publisherFilterType)}
                  helperText={touched.publisherFilterType && errors.publisherFilterType}
                  className={classes.dropDownField}
                  onChange={(e) => {
                    e.target.value === ENTITY_FILTER_TYPE.NONE && setFieldValue("publishers", [])

                    setFieldValue("publisherFilterType", e.target.value)
                  }}
                  value={values.publisherFilterType}
                  inputProps={{
                    "data-testid": "deal-form-publisher-filter-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {entityFilterTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label.replace("list", "")}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Upload Publishers */}
              {!isInfo && (
                <Grid item md={2}>
                  <CsvUpload
                    values={values}
                    setFieldValue={setFieldValue}
                    fieldValue={"publishers"}
                    type={DEAL_CSV_TYPES.PUBLISHERS}
                    uploadedList={uploadedPublishers}
                    uploadSuccessAction={(formattedCSVData: string[]) => {
                      touched.publishers = true
                      dispatch(uploadDealPublishersSuccess(formattedCSVData))
                    }}
                    clearAction={clearUploadedDealPublishers}
                    disabled={isDisabled || values.publisherFilterType === ENTITY_FILTER_TYPE.NONE}
                    toolTipText={"Upload Publishers"}
                    className={classes.filterListButton}
                  />
                </Grid>
              )}
              {/* Export Publishers */}
              <Grid item md={1}>
                <div className={clsx(classes.filterListButtonContainer, classes.filterListButton)}>
                  <Tooltip title={"Export Publishers"}>
                    <Button
                      data-testid={`deal-form-publishers-export-button`}
                      variant="contained"
                      color="secondary"
                      size="small"
                      component="span"
                      disabled={
                        values.publisherFilterType === ENTITY_FILTER_TYPE.NONE ||
                        !values.publishers.length
                      }
                      onClick={() =>
                        exportFilterListAsCsv(values.publishers, {
                          dealName: deal.name,
                          filterName: DEAL_CSV_TYPES.PUBLISHERS,
                        })
                      }
                    >
                      <CloudDownloadOutlined />
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {/*Add Domains*/}
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={selectableDomains}
                  value={values.domains}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  multiple
                  fullWidth
                  freeSolo
                  data-testid={"deal-form-domains-input"}
                  onBlur={() => setFieldTouched("domains")}
                  onChange={(e, newValue) => {
                    newValue.length || dispatch(clearUploadedDealDomains())
                    setDomainsSearchTerm("")
                    dispatch(clearDealDomains())
                    setFieldValue(
                      "domains",
                      newValue.map((domain) =>
                        typeof domain === "string" ? { id: null, name: domain } : domain
                      )
                    )
                  }}
                  onInputChange={(e, inputValue) => {
                    setDomainsSearchTerm(inputValue)
                    dispatch(clearDealDomains())
                    inputValue.length > 1 && debounceFetchDealDomains(inputValue, 0, true)
                  }}
                  ListboxProps={{
                    onScroll: (event: React.SyntheticEvent) => {
                      const listboxNode = event.currentTarget
                      if (
                        listboxNode.scrollTop + listboxNode.clientHeight ===
                        listboxNode.scrollHeight
                      ) {
                        dispatch(
                          fetchDealDomains({
                            term: domainsSearchTerm,
                            page: domainsPage + 1,
                            clear: false,
                          })
                        )
                      }
                    },
                  }}
                  loading={isLoadingDomains}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={
                          <span
                            className={clsx(
                              isInfo && values.domains.length > 0 && classes.overrideDisable
                            )}
                          >
                            Domains
                          </span>
                        }
                        name="domains"
                        error={!!(touched.domains && errors.domains)}
                        helperText={touched.domains && errors.domains}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            disabled:
                              isInfo && values.domains.length > 0 && classes.overrideDisable,
                          },
                        }}
                      />
                    )
                  }}
                  disabled={isDisabled || values.domainFilterType === ENTITY_FILTER_TYPE.NONE}
                />
                {values.domainFilterType === ENTITY_FILTER_TYPE.NONE && !isInfo && (
                  <FormHelperText component="div">
                    <Typography
                      className={classes.helperText}
                      data-testid={"deal-form-domain-filter-helper-text"}
                    >
                      ** Select a Domain Filter Type to add Domains **
                    </Typography>
                  </FormHelperText>
                )}
              </Grid>
              {/* Domain Filter Type */}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={
                    <span className={clsx(isInfo && classes.overrideDisable)}>
                      Domain Filter Type
                    </span>
                  }
                  name="domainFilterType"
                  error={!!(touched.domainFilterType && errors.domainFilterType)}
                  helperText={touched.domainFilterType && errors.domainFilterType}
                  className={clsx(classes.dropDownField)}
                  onChange={(e) => {
                    e.target.value === ENTITY_FILTER_TYPE.NONE && setFieldValue("domains", [])

                    setFieldValue("domainFilterType", e.target.value)
                  }}
                  value={values.domainFilterType}
                  inputProps={{
                    "data-testid": "deal-form-domain-filter-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {entityFilterTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label.replace("list", "")}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Upload Domains */}
              {!isInfo && (
                <Grid item md={2}>
                  <CsvUpload
                    values={values}
                    setFieldValue={setFieldValue}
                    type={DEAL_CSV_TYPES.DOMAINS}
                    uploadedList={uploadedDomains}
                    uploadSuccessAction={(formattedCSVData: string[]) => {
                      touched.domains = true
                      dispatch(uploadDealDomainsSuccess(formattedCSVData))
                    }}
                    fieldValue={"domains"}
                    clearAction={clearUploadedDealDomains}
                    disabled={isDisabled || values.domainFilterType === ENTITY_FILTER_TYPE.NONE}
                    toolTipText={"Upload Domains"}
                    className={classes.filterListButton}
                  />
                </Grid>
              )}
              {/* Export Domains */}
              <Grid item md={1}>
                <div className={clsx(classes.filterListButtonContainer, classes.filterListButton)}>
                  <Tooltip title={"Export Domains"}>
                    <Button
                      data-testid={`deal-form-domains-export-button`}
                      variant="contained"
                      color="secondary"
                      size="small"
                      component="span"
                      disabled={
                        values.domainFilterType === ENTITY_FILTER_TYPE.NONE ||
                        !values.domains.length
                      }
                      onClick={() =>
                        exportFilterListAsCsv(values.domains, {
                          dealName: deal.name,
                          filterName: DEAL_CSV_TYPES.DOMAINS,
                        })
                      }
                    >
                      <CloudDownloadOutlined />
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Box
                  className={clsx(
                    classes.topMostContainer,
                    classes.automaticValueOptimizationButton
                  )}
                >
                  <Typography>
                    <b>Automatic Venue Optimization</b>
                  </Typography>
                </Box>
              </Grid>
              {/*Minimum Viewability Rate*/}
              <Grid item md={3} xs={12}>
                <TextField
                  label={
                    <span
                      className={clsx(isInfo && values.viewRate > 0 && classes.overrideDisable)}
                    >
                      Minimum Viewability Rate (%)
                    </span>
                  }
                  name="viewRate"
                  type="number"
                  error={!!(touched.viewRate && errors.viewRate)}
                  helperText={touched.viewRate && errors.viewRate}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  value={values.viewRate}
                  inputProps={{
                    "data-testid": "deal-form-min-view-rate-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  disabled={isDisabled}
                />
              </Grid>
              {/*"Minimum Video Completion Rate*/}
              <Grid item md={3} xs={12}>
                <TextField
                  label={
                    <span className={clsx(isInfo && values.vcrRate > 0 && classes.overrideDisable)}>
                      Minimum Video Completion Rate (%)
                    </span>
                  }
                  name="vcrRate"
                  type="number"
                  error={!!(touched.vcrRate && errors.vcrRate)}
                  helperText={touched.vcrRate && errors.vcrRate}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vcrRate}
                  variant="outlined"
                  inputProps={{
                    "data-testid": "deal-form-min-vcr-rate-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
          </>
        )
      default:
        return "Unknown step"
    }
  }

  const handleFormikSubmit = (values) => {
    const valuesCopy = _.cloneDeep(values)

    valuesCopy.bidderId = valuesCopy.bidder.id
    valuesCopy.wseatIds.length === 0
      ? delete valuesCopy.wseatIds
      : (valuesCopy.wseatIds = valuesCopy.wseatIds.map((wseatId) => parseInt(wseatId, 10)))

    valuesCopy.segmentGroups =
      valuesCopy.dataConnected === "legacy"
        ? flattenFormattedSegmentGroups(valuesCopy.segmentGroups)
        : []
    valuesCopy.iabContentCategories =
      valuesCopy.iabContentCategoryFilterType === ENTITY_FILTER_TYPE.NONE
        ? []
        : valuesCopy.iabContentCategories.map((iabContentCategory) => iabContentCategory.id)

    switch (valuesCopy.dataConnected) {
      case "false":
        valuesCopy.dmpSegmentFeeFixed = null
        valuesCopy.dmpSegmentFeePercentage = null
        delete valuesCopy.dmpSegmentFeeType
        valuesCopy.externalAudienceId = ""
        valuesCopy.segmentGroups = []
        break
      case "legacy":
        valuesCopy.externalAudienceId = ""
        break
      case "audience":
        valuesCopy.segmentGroups = []
        break
    }

    if (valuesCopy.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.PERCENTAGE) {
      valuesCopy.dmpSegmentFeePercentage = valuesCopy.dmpSegmentFee
    }
    if (valuesCopy.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.FIXED) {
      valuesCopy.dmpSegmentFeeFixed = valuesCopy.dmpSegmentFee
    }
    delete valuesCopy.dmpSegmentFee

    valuesCopy.countries = valuesCopy.countries.map((country) => country.id)

    // publishers can be a mix of strings and objects in the edit case
    valuesCopy.publishers = valuesCopy.publishers.map((publisher) =>
      publisher.id ? publisher.id : publisher
    )

    // clear publishers and domains from payload if filter type is set to none
    valuesCopy.publishers =
      valuesCopy.publisherFilterType === ENTITY_FILTER_TYPE.NONE ? [] : valuesCopy.publishers
    valuesCopy.domains =
      valuesCopy.domainFilterType === ENTITY_FILTER_TYPE.NONE ? [] : valuesCopy.domains

    // TODO: potentially need to clear extra fields being sent, but backend should ignore anyways
    if (isCreate || isCopy) {
      dispatch(
        createDeal({
          ...trimPayloadProperties(valuesCopy),
        } as CreateAndUpdateDealPayload)
      )
      isCopy && setIsCopyDeal(false)
    } else if (isEdit) {
      dispatch(
        updateDeal({
          ...trimPayloadProperties({
            id: deal.id,
            ...valuesCopy,
          }),
        } as CreateAndUpdateDealPayload)
      )
    }
  }

  const getSubmitButtonText = (endPath: string): string => {
    switch (endPath) {
      case END_PATHS.CREATE:
        return "Create Deal"
      case END_PATHS.EDIT:
        return "Update Deal"
      case END_PATHS.COPY:
        return "Save Copied Deal"
      default:
        return "Unknown endPath"
    }
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(
    isCreate,
    isEdit,
    isInfo,
    isCopy,
    deal
  )

  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={formValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleFormikSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            setFieldTouched,
            values,
          }) => {
            return (
              <>
                <ErrorMessages />
                <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                  <Card className={classes.roundedBorder}>
                    <CardContent className={classes.cardStepperPadding}>
                      <div>
                        <Stepper activeStep={-1} nonLinear orientation="vertical">
                          {steps.map((label, index) => (
                            <Step expanded={true} key={`${label.title}-${label.subTitle}-${index}`}>
                              <StepLabel>
                                <Grid container>
                                  <Grid item>
                                    <Typography
                                      display="inline"
                                      key={`${label.title}-${label.subTitle}`}
                                      variant="h4"
                                    >
                                      <b key={`${label.title}-${label.subTitle}-${index}-bold`}>
                                        {label.title}{" "}
                                      </b>{" "}
                                    </Typography>
                                    {label.subTitle && (
                                      <Typography
                                        key={`${label.subTitle}-${index}`}
                                        color="secondary"
                                        display="inline"
                                        variant="h5"
                                      >
                                        {label.subTitle}
                                      </Typography>
                                    )}
                                  </Grid>
                                  {isEdit && index === 0 && (
                                    <Grid item>
                                      <Typography
                                        className={classes.dealIdText}
                                        variant="subtitle1"
                                      >
                                        {`- Deal ID (DID): ${deal.externalId || ""}`}
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              </StepLabel>
                              <StepContent>
                                {getStepContent(
                                  index,
                                  values,
                                  touched,
                                  setFieldTouched,
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  setFieldValue
                                )}
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                      {!isInfo && (
                        <Box mt={2}>
                          {(!_.isEmpty(errors) || generalErrors.length > 0) && (
                            <Alert
                              variant="outlined"
                              severity="error"
                              key="improperly-formatted-form-field"
                              className={classes.formFieldErrors}
                            >
                              Form field(s) are improperly formatted
                            </Alert>
                          )}
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={isDisabled}
                            data-testid={"deal-form-submit-button"}
                          >
                            {isSubmitting && (
                              <CircularProgress size={24} className={classes.buttonProgress} />
                            )}
                            {getSubmitButtonText(endPath)}
                          </Button>
                          <Can
                            perform={PERMISSIONS.TOOLS.DEAL.CREATE}
                            yes={
                              <>
                                {canCopy && (
                                  <Button
                                    className={classes.dealFormButtonSpacing}
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                    disabled={isDisabled}
                                    onClick={() => {
                                      handleCopyDealClick()
                                    }}
                                    data-testid={"deal-form-copy-button"}
                                  >
                                    Copy Deal
                                  </Button>
                                )}
                              </>
                            }
                          />
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </form>
              </>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}

export default withStyles(styles)(DealForm)
