import { Theme, createStyles } from "@material-ui/core/styles"

import { DEFAULT_CSS_STYLES } from "../../constants"

const drawerWidth = 220
const headerHeight = "64px"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  return createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      backgroundColor: theme.palette.white,
      display: "flex",
      flexDirection: "column",
      overflow: "visible",
    },
    drawerOpen: {
      width: drawerWidth,
      maxWidth: "600px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeOut,
      }),
      marginTop: headerHeight,
      height: `calc(100% - ${headerHeight} - 1em - 10px)`,
    },
    arrowIcons: {
      color: "black",
      border: "1px solid #cec9c9",
      borderLeft: 0,
      borderRadius: "0 2px 2px 0",
      padding: 0,
      zIndex: 999,
      right: "-18px",
      background: "#fff",
      top: "97px",
      float: "right",
      cursor: "pointer",
      "&:hover": {
        background: "#fff",
        cursor: "pointer",
      },
      "& svg": {
        width: "16px",
        height: "24px",
      },
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shortest,
      }),
      width: theme.spacing(6) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8) + 1,
      },
      marginTop: headerHeight,
      height: `calc(100% - ${headerHeight} - 1em - 10px)`,
      overflowX: "clip",
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    nav: {
      marginBottom: theme.spacing(2),
    },
    dragger: {
      width: "5px",
      cursor: "ew-resize",
      position: "absolute",
      top: headerHeight,
      right: 0,
      bottom: 0,
      zIndex: 9999,
    },
    versionNumber: {
      fontSize: "10px",
      fontWeight: 500,
      color: "#757575",
      fontFamily: DEFAULT_CSS_STYLES.FONT_FAMILY,
      paddingLeft: "15px",

      position: "absolute",
      bottom: "10px",
      left: "0px",
      background: "white",
      height: "1em",
    },
    sidebarWrapper: {
      position: "relative",
      borderRight: "1px solid #cccccc",
    },
  })
}
